<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Pedidos</h4>
            <div class="small text-muted">Crear y administrar todos los pedidos emitidos y recibidos</div>
          </b-col>
          
          <b-col sm="5">                      
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Pedido">
              <i class="fa fa-plus"></i> Agregar
            </b-button>                  

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>            
          </b-col>                    
        </b-row>
      </b-card>
            
      <b-card id="list-orders" v-if="table.mostrarFiltros">
        <b-row >
          <b-col md="11">            
            <b-row>        
              <b-col md="3">    
                <b-row>        
                  <b-col md="6" class="p-0 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="6" class="p-0 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col>
                  <b-col sm="12" class="p-0"> 
                    <b-form-input type="number" 
                                  placeholder="Buscar por Nº de Pedido" 
                                  v-on:keyup.enter.native="filterOrders()"
                                  v-model="filters.number">
                    </b-form-input>         
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">    
                <b-row>
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.status" v-model="filters.status" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>  
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>
                  <b-col sm="6" class="p-1" v-if="moduleSellersActive">
                    <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                      
                </b-row>
              </b-col>
              <b-col md="3">   
                <b-row>                           
                  <b-col sm="12" class="p-1" v-if="parameters.remitFromOrder">
                    <v-select :options="arr.filters.remitted" v-model="filters.remitted" placeholder="Remitido" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>   
                  <b-col sm="12" class="p-1" v-if="parameters.haveReason">
                    <v-select :options="arr.filters.reasons" v-model="filters.reason" placeholder="Motivos" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                                    
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1" class="p-1">
            <b-row>
              <b-col sm="12" class="p-1">
                <b-button variant="outline-dark" @click="filterOrders()" size="sm" class="pull-right mr-2" title="Filtrar">
                  <b-icon icon="filter" style="font-size:15px;"></b-icon>              
                </b-button>
              </b-col>              
              <b-col sm="12" class="p-1">
                <b-link>
                  <export-excel
                    class = 'pull-right mr-2 mt-2'
                    :data = "arr.export"
                    worksheet = "Listado de Pedidos"
                    name = "list-orders.xls">
                    Exportar <b-icon icon="download"></b-icon>
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>              
      </b-card>
      
      <b-row>                
        <b-col lg="8" class="pr-0" id="view_list_orders">
          <b-card no-body>
            <b-tabs pills card vertical>
              <b-tab>
                <template #title>
                  <b-icon icon="receipt-cutoff" title="Pedidos"></b-icon>
                </template>                
                <b-card-text>
                  <b-card>   
                    <b-row>
                      <b-col sm="12">
                        <b-form-checkbox v-model="filters.show_total_iva" 
                                          switch 
                                          size="sm" 
                                          class="pull-left"
                                          @input="filterOrders()">
                          Mostrar totales con IVA incluido
                        </b-form-checkbox>                 
                      </b-col>
                    </b-row>
                                           
                    <b-tabs justified active-nav-item-class="font-weight-bold text-uppercase text-dark" id="tab-orders">
                      <b-tab title="Mayorista" v-if="showView.wholesaler" @click="selectRenderView('wholesaler')">                
                    
                        <b-table class="mb-0 table-orders-custom"
                                ref="table"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="table.items"
                                :fields="table.fields"
                                :filter="table.filter"
                                :current-page="table.currentPage"
                                :per-page="table.perPage"
                                selectable
                                select-mode="single"
                                @row-selected="onRowSelected"  
                                :busy="table.isBusy"                      
                                v-if="table.items.length || table.isBusy">                       
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Cargando...</strong>
                              </div>
                            </template>
                    
                            <template v-slot:cell(business)="data">
                              <div v-if="data.item.points_sales && data.item.points_sales.business">
                                {{data.item.points_sales.business.reference}}
                              </div>
                            </template>

                            <template v-slot:cell(id)="data">
                              <!--
                              <span v-if="data.item.points_sales" :title="'Punto de Venta: (' + data.item.points_sales.point_sale + ') ' + data.item.points_sales.name">
                                <b-badge variant="dark">{{data.item.points_sales.point_sale}}</b-badge>
                              </span>
                              -->

                              <div title="Numeración del sistema" v-if="data.item.points_sales">
                                PEDIDO <br>
                                {{data.item.points_sales.point_sale.toString().padStart(4,'0') + '-' + data.item.id.toString().padStart(8,'0')}}
                              </div>
                              <div v-if="data.item.nro_order_internal" 
                                  title="Numeración de un sistema externo"
                                  class="crud-orders-nro-internal">
                                <b-icon icon="link45deg"></b-icon>
                                <b>{{data.item.nro_order_internal}}</b>
                              </div>
                            </template>

                            <template v-slot:cell(date)="data">
                              {{moment(data.item.date).format('DD/MM/YYYY')}}
                            </template>

                            <template v-slot:cell(sellers_id)="data">                      
                              <span v-if="data.item.sellers_id" v-b-tooltip.hover :title="'VENDEDOR: ' + data.item.seller.staff.name">
                                <b-avatar :src="data.item.seller.staff.image"
                                          v-if="data.item.seller.staff.image">
                                </b-avatar>
                                <b-avatar v-else                                                                         
                                          variant="dark">
                                </b-avatar>                             
                              </span>       
                            </template>

                            <template v-slot:cell(staff_id)="data">                      
                              <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                                <b-avatar :src="data.item.staff.image"
                                          v-if="data.item.staff.image">
                                </b-avatar>
                                <b-avatar v-else                                                                         
                                          variant="dark">
                                </b-avatar>              
                              </span>               
                            </template>
                            
                            <template v-slot:cell(customers_id)="data">  
                              <div v-if="data.item.customer">            
                                <b-avatar :src="data.item.customer.image"
                                          v-if="data.item.customer.image">
                                </b-avatar>
                                <b-avatar v-else 
                                          icon="building"                                            
                                          variant="dark">
                                </b-avatar>                                                     
                                &nbsp;<b>{{data.item.customer.name}}</b>   
                                
                                <span v-if="data.item.customer.bcra_status">                                                    
                                  <span v-if="data.item.customer.bcra_status.situacion > situationCreditMax">
                                    &nbsp;
                                    <b-badge variant="danger" :id="'popover-situation-' + data.item.id">
                                      Sit {{data.item.customer.bcra_status.situacion}}
                                    </b-badge>
                                    <b-popover :target="'popover-situation-' + data.item.id" variant="danger" triggers="hover">
                                      <template #title>Riesgo Creditício</template>
                                      <div v-html="situationCreditMessage(data.item)"></div>
                                    </b-popover>                            
                                  </span>
                                </span>                                                

                                <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                              </div>
                            </template>

                            <template v-slot:cell(total)="data">       
                              <div v-if="data.item.status!='in_process'">
                                <div v-if="data.item.paid" class="order-total-table-paid">
                                  <span v-if="filters.show_total_iva">
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.totalIva)}}
                                  </span>
                                  <span v-else>
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                  </span>
                                </div>
                                <div v-else class="order-total-table-nopaid">
                                  <span v-if="filters.show_total_iva">
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.totalIva)}}
                                  </span>
                                  <span v-else>
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                  </span>
                                </div>
                              </div>
                            </template>

                            <template v-slot:cell(status)="data">       
                              <div v-html="getStatusName(data.item)"></div>
                            </template>

                            <template v-slot:cell(reason)="data">                                     
                              <div v-if="data.item.reason">
                                <span v-if="data.item.reason.reference">
                                  {{data.item.reason.reference}}
                                </span>
                                <span v-else>
                                  {{data.item.reason.name}}
                                </span>                                                                
                              </div>
                            </template>

                            <template v-slot:cell(status_system_manufacture)="data">                      
                              <span v-if="data.item.status_system_manufacture">                                                           
                                <span v-if="JSON.parse(data.item.status_system_manufacture).status == 'error'" v-b-tooltip.hover :title="JSON.parse(data.item.status_system_manufacture).message">
                                  <b-icon icon="arrow-return-right" style="color: #ff1f1f;"></b-icon>                          
                                </span>                                   
                              </span>
                            </template>

                            <template v-slot:cell(observations)="data">
                              <div v-if="data.item.observation" v-b-tooltip.hover :title="data.item.observation">
                                <b-icon icon="chat-square-text"></b-icon>
                              </div>                        
                              <div v-if="data.item.observations_external">
                                <b-link :id="'popover-observations-external-' + data.index">
                                  <b-icon icon="chat-square-text-fill"></b-icon>
                                </b-link>
                              </div>   
                              <b-popover :target="'popover-observations-external-' + data.index" 
                                        variant="danger" 
                                        triggers="hover">
                                <template #title>Notas</template>
                                <div v-html="data.item.observations_external"></div>
                              </b-popover>      
                            </template>
                            
                            <template v-slot:cell(paid_info)="data">
                              <div v-if="data.item.paid_info" v-b-tooltip.hover :title="data.item.paid_info">
                                <b-icon icon="cash-stack"></b-icon>
                              </div>                        
                            </template>

                            <template v-slot:cell(delivery_code_tracking)="data">
                              <div v-if="data.item.delivery_code_tracking" v-b-tooltip.hover :title="data.item.delivery_code_tracking">
                                <b-icon icon="truck"></b-icon>
                              </div>                        
                            </template>                    

                            <template v-slot:cell(invoice)="data">
                              <div v-if="data.item.sale_id" v-b-tooltip.hover :title="data.item.sale.type_voucher.name + ' ' + data.item.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.sale.number.toString().padStart(8,'0')">
                                <b-icon icon="receipt-cutoff"></b-icon>
                              </div>                                              
                            </template>

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right 
                                          text="" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right" 
                                          @show="setConfigTableOrdersShow"
                                          @hide="setConfigTableOrdersHide">
                                
                                <b-dropdown-item @click="sendPrinterOrder(data.item)" v-if="data.item.status!='in_process'">
                                  <b-icon icon="printer"></b-icon> Imprimir
                                </b-dropdown-item>                          
                                <b-dropdown-item @click="sendEmailOrder(data.item)" v-if="data.item.status!='in_process'">
                                  <b-icon icon="envelope"></b-icon> Reenviar Email 
                                </b-dropdown-item>  

                                <b-dropdown-header v-if="showGenerateRemits(data.item) || showGenerateInvoice(data.item)">
                                  ERP
                                </b-dropdown-header> 
                                <b-dropdown-item @click="generateRemits(data.item)" v-if="showGenerateRemits(data.item)">
                                  <b-icon icon="file-earmark-ruled"></b-icon> Remitar
                                </b-dropdown-item>   
                                <b-dropdown-item @click="generateInvoice(data.item)" v-if="showGenerateInvoice(data.item)">
                                  <b-icon icon="file-earmark-ruled"></b-icon> Facturar
                                </b-dropdown-item>   

                                <b-dropdown-header v-if="showNextStatus(data.item) || showPreviousStatus(data.item) || showCancelStatus(data.item) || showRestoreStatus(data.item)">
                                  Estados
                                </b-dropdown-header>                         
                                <b-dropdown-item @click="nextStatus(data.item)" v-if="showNextStatus(data.item)">
                                  <b-icon icon="arrow-return-right"></b-icon> Próximo Estado
                                </b-dropdown-item>                             
                                <b-dropdown-item @click="previousStatus(data.item)" v-if="showPreviousStatus(data.item)">
                                  <b-icon icon="arrow-return-left"></b-icon> Estado Anterior 
                                </b-dropdown-item>     

                                <b-dropdown-item @click="cancelStatus(data.item)" v-if="showCancelStatus(data.item)">
                                  <b-icon icon="dash-circle-fill"></b-icon> Cancelar Pedido 
                                </b-dropdown-item>   
                                <b-dropdown-item @click="closedStatus(data.item)" v-if="showRestoreStatus(data.item)">
                                  <b-icon icon="arrow-counterclockwise"></b-icon> Reestablecer Pedido 
                                </b-dropdown-item>                                                 

                                <b-dropdown-header v-if="parameters.haveNroInteral && data.item.status!='in_process'">
                                  Asociación
                                </b-dropdown-header>
                                <b-dropdown-item @click="openNroOrderInternal(data.item)" v-if="parameters.haveNroInteral && data.item.status!='in_process'">
                                  <b-icon icon="link45deg"></b-icon> Agregar Nro Interno
                                </b-dropdown-item>                         
                                                        
                                <b-dropdown-header>Acciones</b-dropdown-header>
                                <b-dropdown-item @click="edit(data.item)" v-if="data.item.status=='closed'">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteOrders">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>
                        </b-table>
                        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

                      </b-tab>
                      <b-tab title="Minorista" v-if="showView.retailer" @click="selectRenderView('retailer')">

                        <b-table class="mb-0 table-orders-custom"
                                ref="table"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="table.items"
                                :fields="table.fields"
                                :filter="table.filter"
                                :current-page="table.currentPage"
                                :per-page="table.perPage"
                                selectable
                                select-mode="single"
                                @row-selected="onRowSelected"  
                                :busy="table.isBusy"                      
                                v-if="table.items.length || table.isBusy">                       
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Cargando...</strong>
                              </div>
                            </template>

                            <template v-slot:cell(business)="data">
                              <div v-if="data.item.points_sales && data.item.points_sales.business">
                                {{data.item.points_sales.business.reference}}
                              </div>
                            </template>

                            <template v-slot:cell(id)="data">
                              <!--
                              <span v-if="data.item.points_sales" :title="'Punto de Venta: (' + data.item.points_sales.point_sale + ') ' + data.item.points_sales.name">
                                <b-badge variant="dark">{{data.item.points_sales.point_sale}}</b-badge>
                              </span>
                              -->

                              <div title="Numeración del sistema">                                
                                <div v-if="data.item.points_sales">
                                  PEDIDO <br>
                                  {{data.item.points_sales.point_sale.toString().padStart(4,'0') + '-' + data.item.id.toString().padStart(8,'0')}}
                                </div>                                
                              </div>
                              <div v-if="data.item.nro_order_internal" 
                                  title="Numeración de un sistema externo"
                                  class="crud-orders-nro-internal">
                                <b-icon icon="link45deg"></b-icon>
                                <b>{{data.item.nro_order_internal}}</b>
                              </div>
                            </template>

                            <template v-slot:cell(date)="data">
                              {{moment(data.item.date).format('DD/MM/YYYY')}}
                            </template>

                            <template v-slot:cell(sellers_id)="data">                      
                              <span v-if="data.item.sellers_id" v-b-tooltip.hover :title="'VENDEDOR: ' + data.item.seller.staff.name">
                                <b-avatar :src="data.item.seller.staff.image"
                                          v-if="data.item.seller.staff.image">
                                </b-avatar>
                                <b-avatar v-else                                                                         
                                          variant="dark">
                                </b-avatar>                             
                              </span>       
                            </template>

                            <template v-slot:cell(staff_id)="data">                      
                              <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                                <b-avatar :src="data.item.staff.image"
                                          v-if="data.item.staff.image">
                                </b-avatar>
                                <b-avatar v-else                                                                         
                                          variant="dark">
                                </b-avatar>              
                              </span>               
                            </template>
                            
                            <template v-slot:cell(customers_id)="data">  
                              <div v-if="data.item.customer">            
                                <b-avatar :src="data.item.customer.image"
                                          v-if="data.item.customer.image">
                                </b-avatar>
                                <b-avatar v-else 
                                          icon="building"                                            
                                          variant="dark">
                                </b-avatar>                                                     
                                &nbsp;<b>{{data.item.customer.name}}</b>   
                                
                                <span v-if="data.item.customer.bcra_status">                                                    
                                  <span v-if="data.item.customer.bcra_status.situacion > situationCreditMax">
                                    &nbsp;
                                    <b-badge variant="danger" :id="'popover-situation-' + data.item.id">
                                      Sit {{data.item.customer.bcra_status.situacion}}
                                    </b-badge>
                                    <b-popover :target="'popover-situation-' + data.item.id" variant="danger" triggers="hover">
                                      <template #title>Riesgo Creditício</template>
                                      <div v-html="situationCreditMessage(data.item)"></div>
                                    </b-popover>                            
                                  </span>
                                </span>                                                

                                <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                              </div>
                            </template>

                            <template v-slot:cell(total)="data">       
                              <div v-if="data.item.status!='in_process'">
                                <div v-if="data.item.paid" class="order-total-table-paid">
                                  <span v-if="filters.show_total_iva">
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.totalIva)}}
                                  </span>
                                  <span v-else>
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                  </span>
                                </div>
                                <div v-else class="order-total-table-nopaid">
                                  <span v-if="filters.show_total_iva">
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.totalIva)}}
                                  </span>
                                  <span v-else>
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                  </span>
                                </div>
                              </div>
                            </template>

                            <template v-slot:cell(status)="data">       
                              <div v-html="getStatusName(data.item)"></div>
                            </template>

                            <template v-slot:cell(reason)="data">                                     
                              <div v-if="data.item.reason">{{data.item.reason.name}}</div>
                            </template>

                            <template v-slot:cell(status_system_manufacture)="data">                      
                              <span v-if="data.item.status_system_manufacture">                                                           
                                <span v-if="JSON.parse(data.item.status_system_manufacture).status == 'error'" v-b-tooltip.hover :title="JSON.parse(data.item.status_system_manufacture).message">
                                  <b-icon icon="arrow-return-right" style="color: #ff1f1f;"></b-icon>                          
                                </span>                                   
                              </span>
                            </template>

                            <template v-slot:cell(observations)="data">
                              <div v-if="data.item.observation" v-b-tooltip.hover :title="data.item.observation">
                                <b-icon icon="chat-square-text"></b-icon>
                              </div>                        
                              <div v-if="data.item.observations_external">
                                <b-link :id="'popover-observations-external-' + data.index">
                                  <b-icon icon="chat-square-text-fill"></b-icon>
                                </b-link>
                              </div>   
                              <b-popover :target="'popover-observations-external-' + data.index" 
                                        variant="danger" 
                                        triggers="hover">
                                <template #title>Notas</template>
                                <div v-html="data.item.observations_external"></div>
                              </b-popover>      
                            </template>
                            
                            <template v-slot:cell(paid_info)="data">
                              <div v-if="data.item.paid" v-b-tooltip.hover :title="data.item.paid_info">
                                <b-icon icon="cash-stack"></b-icon>
                              </div>                        
                            </template>

                            <template v-slot:cell(delivery_code_tracking)="data">
                              <div v-if="data.item.delivery_code_tracking" v-b-tooltip.hover :title="data.item.delivery_code_tracking">
                                <b-icon icon="truck"></b-icon>
                              </div>                        
                            </template>                    

                            <template v-slot:cell(invoice)="data">
                              <div v-if="data.item.sale_id" v-b-tooltip.hover :title="data.item.sale.type_voucher.name + ' ' + data.item.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.sale.number.toString().padStart(8,'0')">
                                <b-icon icon="receipt-cutoff"></b-icon>
                              </div>                                              
                            </template>

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right 
                                          text="" 
                                          size="sm" 
                                          variant="outline-dark" 
                                          class="pull-right" 
                                          @show="setConfigTableOrdersShow"
                                          @hide="setConfigTableOrdersHide">
                                
                                <b-dropdown-item @click="sendPrinterOrder(data.item)" v-if="data.item.status!='in_process'">
                                  <b-icon icon="printer"></b-icon> Imprimir
                                </b-dropdown-item>                          
                                <b-dropdown-item @click="sendEmailOrder(data.item)" v-if="data.item.status!='in_process'">
                                  <b-icon icon="envelope"></b-icon> Reenviar Email 
                                </b-dropdown-item>  
                                
                                <b-dropdown-header v-if="showGenerateRemits(data.item) || showGenerateInvoice(data.item)">
                                  ERP
                                </b-dropdown-header> 
                                <b-dropdown-item @click="generateRemits(data.item)" v-if="showGenerateRemits(data.item)">
                                  <b-icon icon="file-earmark-ruled"></b-icon> Remitar
                                </b-dropdown-item>   
                                <b-dropdown-item @click="generateInvoice(data.item)" v-if="showGenerateInvoice(data.item)">
                                  <b-icon icon="file-earmark-ruled"></b-icon> Facturar
                                </b-dropdown-item> 

                                <b-dropdown-header v-if="showNextStatus(data.item) || showPreviousStatus(data.item) || showCancelStatus(data.item) || showRestoreStatus(data.item)">
                                  Estados
                                </b-dropdown-header>                         
                                <b-dropdown-item @click="nextStatus(data.item)" v-if="showNextStatus(data.item)">
                                  <b-icon icon="arrow-return-right"></b-icon> Próximo Estado
                                </b-dropdown-item>                             
                                <b-dropdown-item @click="previousStatus(data.item)" v-if="showPreviousStatus(data.item)">
                                  <b-icon icon="arrow-return-left"></b-icon> Estado Anterior 
                                </b-dropdown-item>     

                                <b-dropdown-item @click="cancelStatus(data.item)" v-if="showCancelStatus(data.item)">
                                  <b-icon icon="dash-circle-fill"></b-icon> Cancelar Pedido 
                                </b-dropdown-item>   
                                <b-dropdown-item @click="closedStatus(data.item)" v-if="showRestoreStatus(data.item)">
                                  <b-icon icon="arrow-counterclockwise"></b-icon> Reestablecer Pedido 
                                </b-dropdown-item>   

                                <b-dropdown-header v-if="showPaid(data.item) || showCancelPaid(data.item)">
                                  Pagos
                                </b-dropdown-header>
                                <b-dropdown-item @click="getLinkPay(data.item)" v-if="showPaid(data.item)">
                                  <b-icon icon="link45deg"></b-icon> Obtener Link
                                </b-dropdown-item>                          
                                <b-dropdown-item @click="changePaid(data.item)" v-if="showPaid(data.item)">
                                  <b-icon icon="cash"></b-icon> Forzar pagado 
                                </b-dropdown-item>  
                                <b-dropdown-item @click="changePaid(data.item)" v-if="showCancelPaid(data.item)">
                                  <b-icon icon="x-circle"></b-icon> Cancelar pago 
                                </b-dropdown-item>                                                  

                                <b-dropdown-header v-if="parameters.haveNroInteral && data.item.status!='in_process'">
                                  Asociación
                                </b-dropdown-header>
                                <b-dropdown-item @click="openNroOrderInternal(data.item)" v-if="parameters.haveNroInteral && data.item.status!='in_process'">
                                  <b-icon icon="link45deg"></b-icon> Agregar Nro Interno
                                </b-dropdown-item>                         
                                                        
                                <b-dropdown-header>Acciones</b-dropdown-header>
                                <b-dropdown-item @click="edit(data.item)" v-if="data.item.status=='closed'">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteOrders">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>
                        </b-table>
                        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

                      </b-tab>
                    </b-tabs>                    
                  </b-card>   
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-icon icon="graph-up" title="Métricas"></b-icon>
                </template>                
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-button @click="filterOrdersMetrics()" size="sm" type="button" variant="outline-dark" class="btn-pill float-right mb-3" v-b-tooltip.hover title="Actualizar Datos">
                        <i class="fa fa-refresh"></i> Actualizar datos
                      </b-button>                    
                    </b-col>                  
                    <b-col md="12">                  
                      <Metrics types="orders" :filters="filters" :key="itemFilterForceUpdate" v-if="itemFilterForceUpdate>0"/>                  
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PEDIDO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <div class="crud-orders-title" v-if="itemSelected">
                        <div class="crud-orders-title">
                          <span class="crud-orders-code" v-if="itemSelected.points_sales">
                            PEDIDO {{itemSelected.points_sales.point_sale.toString().padStart(4,'0') + '-' + itemSelected.id.toString().padStart(8,'0')}}                   
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">     
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                     
                      <b-table  class="mb-0 table-orders-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                        
                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.quantity}}                          
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getProductName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>          

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_orders">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterOrders()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <div id="link_container"></div>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-orders-wizard">
          
          <tab-content title="General" icon="fa fa-edit" :before-change="orderValidDetailGeneral">          
            <b-row>      
              <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :disabled="crud.form.id>0"/>                 
              </b-col>                  
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            display="full_identification"
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.erp_points_sales_id"
                            :key="crud.form.erp_points_sales_id" 
                            :showAllRegister="true" />                 
              </b-col>                
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" />                 
              </b-col>
              <b-col md="4">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id" 
                            :where="conditionPriceListActive"
                            :disabled="crud.form.id>0" />                 
              </b-col>  
              <b-col md="4" v-if="showShipping">
                <FindObject render="search"
                            type="suppliers" 
                            @select-object="loadShipping($event)" 
                            :where="conditionSuppliersShippingActive"
                            :valueID="crud.form.shipping_id"
                            tag="Transporte"                            
                            :key="crud.form.shipping_id"/>                
              </b-col>                            

              <b-col md="4" v-if="parameters.haveReason">
                <FindObject render="search"
                            type="orders-reason" 
                            @select-object="loadReason($event)"                             
                            :valueID="crud.form.reason_id"
                            tag="Motivos"                            
                            :key="crud.form.reason_id"
                            :where="conditionReason"
                            :disabled="crud.form.id>0" />                
              </b-col>                                          
            </b-row>
          </tab-content>
          
          <tab-content title="Productos" icon="fa fa-qrcode" :before-change="orderValidDetailItems">          
            <b-row>
              <b-col lg="12" md="12" sm="12">
                  <Detail :customer_id="crud.form.customers_id" 
                          :price_list_id="crud.form.price_list_id"
                          :reason_id="crud.form.reason_id"
                          @load-detail="crud.form.detail = $event" 
                          :editPrice="editPrice"
                          :validStock="validStock"
                          :detailEdit="crud.form.detail"
                          :showDiscount="false"
                          :key="'detailOrder_'+itemDetailForceUpdate"
                          :forceUpdate="itemDetailForceUpdate"                          
                          :validItemDuplicated="!allowProductsDuplicates"
                          :getItemOnlyPublished="showItemOnlyPublished" />
                  
                  <b-row class="mt-3 p-3" align-v="end">
                    <b-col md="2">
                      <b-row>
                        <b-col md="5" class="crud-orders-totales-items-header">
                          ITEMS
                        </b-col>
                        <b-col md="7" class="crud-orders-counts-items">
                          <b>{{this.getCantItemOrder}}</b>
                        </b-col>
                      </b-row>
                    </b-col>                    
                    <b-col md="6"></b-col>
                    <b-col md="4" v-if="getCurrencyOrder">
                      <b-row>
                        <b-col md="6" class="crud-orders-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-orders-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(getSubTotalOrder)}}                          
                        </b-col>                    
                      </b-row>
                      
                      <b-row>
                        <b-col md="6" class="crud-orders-totales-items-header crud-orders-totales-discounts-items text-uppercase">
                          {{ this.getTagDiscount }} (%)
                        </b-col>                     
                        <b-col md="6" class="crud-orders-totales-items">
                          <b-row>                            
                            <b-col class="p-0">    
                              <b-form-select 
                                v-if="getValueDiscount && getValueDiscount.length"
                                v-model="crud.form.percentage_discount" 
                                :options="getValueDiscount"
                                size="sm"
                                @input="validDiscounts()">
                              </b-form-select>                                                             
                              <b-form-input 
                                v-if="!getValueDiscount || !getValueDiscount.length"
                                v-model="crud.form.percentage_discount" 
                                type="number" 
                                min="0" 
                                max="100"
                                step="1" 
                                class="form-control"
                                size="sm"
                                @change="validDiscounts()"/>
                            </b-col>
                            <b-col class="crud-orders-totales-discounts-items">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(crud.form.discounts_price)}}
                            </b-col>
                          </b-row>
                        </b-col>                    
                      </b-row>                            
                      <b-row>
                        <b-col md="6" class="crud-orders-totales-items-header">
                          TOTAL
                        </b-col>
                        <b-col md="6" class="crud-orders-totales-items-amount">                          
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(getTotalOrder)}}</b>
                        </b-col>                    
                      </b-row>                                                                  
                    </b-col>
                  </b-row>
                                    
                  
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="6">                                
                
                <div v-if="crud.form.customers">            
                  <b-avatar :src="crud.form.customers.image"
                            v-if="crud.form.customers.image">
                  </b-avatar>
                  <b-avatar v-else 
                            icon="building"                                            
                            variant="dark">
                  </b-avatar>                                                     
                  &nbsp;<b>{{crud.form.customers.name}}</b>   
                  
                  <span v-if="crud.form.customers.bcra_status">                                                    
                    <span v-if="crud.form.customers.bcra_status.situacion > situationCreditMax">
                      &nbsp;
                      <b-badge variant="danger" :id="'popover-situation-' + crud.form.id">
                        Sit {{crud.form.customers.bcra_status.situacion}}
                      </b-badge>
                      <b-popover :target="'popover-situation-' + crud.form.id" variant="danger" triggers="hover">
                        <template #title>Riesgo Creditício</template>
                        <div v-html="situationCreditMessage(crud.form)"></div>
                      </b-popover>                            
                    </span>
                  </span>                                                

                  <b-icon icon="circle-fill" v-if="!crud.form.customers.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                

                  <div v-if="crud.form.customers" class="ml-3">
                    <div v-if="!crud.form.customers.email">
                      <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                      <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
                    </div>
                    <div v-else>
                      <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                      <b-link :href="'mailto:' + crud.form.customers.email">
                        {{ crud.form.customers.email }}
                      </b-link> 
                    </div>
                  </div>
                  
                  <div v-if="crud.form.customers.phone" class="ml-3">
                    <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
                    <b-link :href="'tel:' + crud.form.customers.phone">
                      {{ crud.form.customers.phone }}
                    </b-link>               
                  </div>      

                  <b-row v-if="crud.form.customers.observations || crud.form.customers.observations_delivery || crud.form.customers.observations_delivery_address">
                    <b-col>
                      <hr>
                    </b-col>
                  </b-row>
          
                  <b-row v-if="crud.form.customers.observations"  class="mt-2">
                    <b-col class="crud-orders-observations">
                      <b>Observaciones</b>
                      <br>
                      {{ crud.form.customers.observations }}
                    </b-col>
                  </b-row>
                  <b-row v-if="crud.form.customers.observations_delivery"  class="mt-2">              
                    <b-col class="crud-orders-observations">
                      <b>Transporte</b>
                      <br>
                      {{ crud.form.customers.observations_delivery }}
                    </b-col>
                  </b-row>
                  <b-row v-if="crud.form.customers.observations_delivery_address" class="mt-2">
                    <b-col class="crud-orders-observations">
                      <b>Dirección de Entrega</b>
                      <br>
                      {{ crud.form.customers.observations_delivery_address }}
                    </b-col>
                  </b-row> 
                </div>

                <b-row v-if="!crud.form.id">
                  <b-col md="12">
                    <hr class="mt-2 mb-2">
                  </b-col>
                  <b-col md="12">                    
                    <b-form-checkbox v-model="crud.form.send_notification_email_customer" switch class="mt-2 mb-4">
                      Enviar notificación por email al cliente
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="12" v-if="crud.form.send_notification_email_customer">
                    <b-form-group label="Email Alternativo" description="Este email se agrega a la lista de receptores del cliente para este pedido">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.email_alternative"
                                    lazy
                                    required
                                    placeholder="Ingresar un email alternativo">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                         
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                
                        v-model="crud.form.observations"
                        placeholder="Ingresar las observaciones..."
                        rows="5"
                        max-rows="7"
                        lazy
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>         

                  <b-col md="12" class="mt-2">
                    <b-form-group label="Observaciones Internas (No se muestra al cliente)">
                      <b-form-textarea                
                        v-model="crud.form.observations_internal"
                        placeholder="Ingresar las observaciones internas..."
                        rows="5"
                        max-rows="7"
                        lazy
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>         
                </b-row>
              </b-col>
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">
            
            <div v-if="itemSelected">
              <b-alert show variant="secondary" v-if="this.itemSelected.is_retail">
                Venta Minorista
              </b-alert>
              <b-alert show variant="primary" v-if="!this.itemSelected.is_retail">
                Venta Mayorista
              </b-alert>
            </div>

            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

              <b-tab title="General" v-if="itemSelected">
                <table class="table table-hover table-sm">
                  <tbody>
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">ID</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.points_sales && itemSelected.points_sales.business">
                      <td class="crud-orders-title-table-custom align-middle">Empresa</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.business.reference}}
                      </td>
                    </tr>                                                      
                    <tr v-if="itemSelected.points_sales">
                      <td class="crud-orders-title-table-custom align-middle">Número</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>PEDIDO {{this.itemSelected.points_sales.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.id.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                   
                    <tr v-if="itemSelected.nro_order_internal">
                      <td class="crud-orders-title-table-custom align-middle">Numeración Interna</td>
                      <td class="crud-orders-value-table-custom align-middle" style="color: var(--mdc-theme-primary)">
                        <b>
                          <b-icon icon="link45deg"></b-icon>
                          {{this.itemSelected.nro_order_internal}}
                        </b>
                      </td>
                    </tr>                     
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Fecha</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                                                       
                    <tr v-if="itemSelected.points_sales">
                      <td class="crud-orders-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.point_sale}} - {{this.itemSelected.points_sales.name}}                          
                      </td>
                    </tr>                     
                    <tr v-if="itemSelected.customer">
                      <td class="crud-orders-title-table-custom align-middle">Cliente</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <u>{{this.itemSelected.customer.name}}</u>
                        <div v-if="itemSelected.customer.phone">
                          <b-icon icon="telephone-fill"></b-icon>
                          {{this.itemSelected.customer.phone}}     
                        </div>
                        <div v-if="itemSelected.customer.email">
                          <b-icon icon="inbox"></b-icon>
                          {{this.itemSelected.customer.email}}     
                        </div>                        
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.user">
                      <td class="crud-orders-title-table-custom align-middle">Usuario</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.user.name}}                        
                      </td>
                    </tr>                     
                    <tr v-if="itemSelected.reason">
                      <td class="crud-orders-title-table-custom align-middle">Motivo</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.reason.name}}                        
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.method_payment">
                      <td class="crud-orders-title-table-custom align-middle">Método de Pago</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.method_payment.name}}                        
                      </td>
                    </tr>         
                    <tr v-if="itemSelected.shipping">
                      <td class="crud-orders-title-table-custom align-middle">Transporte</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.shipping.name}}                        
                      </td>
                    </tr>                                      
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Estado</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <span v-html="getStatusName(itemSelected)"></span>
                        <span v-if="itemSelected.status_system_manufacture" style="font-size: 10px;" class="ml-1">
                          <span v-if="JSON.parse(itemSelected.status_system_manufacture).status == 'error'" style="color:#ff1f1f;">
                            <i>{{JSON.parse(itemSelected.status_system_manufacture).message}}</i>
                          </span>
                        </span>
                      </td>
                    </tr>                                     
                    <tr v-if="itemSelected.status!='in_process'">
                      <td class="crud-orders-title-table-custom align-middle">SubTotal</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.subtotalItemSelected)}}
                      </td>
                    </tr>  
                    <tr v-if="parseFloat(itemSelected.discounts_price)">
                      <td class="crud-orders-title-table-custom align-middle">{{ this.getTagDiscount }}</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.discounts_price)}}
                          <span v-if="parseFloat(itemSelected.percentage_discount)>0">
                            ({{parseFloat(this.itemSelected.percentage_discount)}} %)
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="itemSelected.delivery_info">
                      <td class="crud-orders-title-table-custom align-middle">Entrega</td>
                      <td class="crud-orders-value-table-custom align-middle">                                                
                        <div>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.delivery_price)}}
                        </div>
                        {{this.itemSelected.delivery_info}} 
                      </td>
                    </tr>
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Total S/IVA</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          <div v-if="itemSelected.paid" class="order-total-table-paid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                          </div>
                          <div v-else class="order-total-table-nopaid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                          </div>
                        </div>
                      </td>
                    </tr>  
                    <tr v-if="itemSelected.status!='in_process'">
                      <td class="crud-orders-title-table-custom align-middle">IVA</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.iva)}}
                      </td>
                    </tr>                     
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Total C/IVA</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          <div v-if="itemSelected.paid" class="order-total-table-paid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.totalIva)}}</b>
                          </div>
                          <div v-else class="order-total-table-nopaid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.totalIva)}}</b>
                          </div>
                        </div>
                      </td>
                    </tr>  

                    <tr v-if="itemSelected.street_address">
                      <td class="crud-orders-title-table-custom align-middle">Dirección</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.street_address}} {{this.itemSelected.street_number}} {{this.itemSelected.apartament_floor}}
                        <br>
                        {{this.itemSelected.city}} ({{this.itemSelected.postal_code}}), {{this.itemSelected.province}}
                      </td>
                    </tr>
                    <tr v-if="itemSelected.observation">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Entrega</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.observation}}
                      </td>
                    </tr>     
                    <tr v-if="itemSelected.observations_internal">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Internas</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.observations_internal}}
                      </td>
                    </tr>                         
                    <tr v-if="itemSelected.observations_external">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones External</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div v-html="itemSelected.observations_external"></div>
                      </td>
                    </tr>                                       
                    <tr v-if="itemSelected.paid_info">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Pago</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.paid_info}}
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.delivery_code_tracking">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Transporte</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.delivery_code_tracking}}
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.sale && itemSelected.sale.type_voucher && itemSelected.sale.point_sale">
                      <td class="crud-orders-title-table-custom align-middle">Factura</td>                      
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>{{this.itemSelected.sale.type_voucher.name + ' ' + this.itemSelected.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.sale.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                                   
                  </tbody>
                </table>
              </b-tab>  
              <b-tab title="Consumidor Final" v-if="itemSelected && itemSelected.consumer">                
                <table class="table table-hover table-sm">
                  <tbody>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).name && JSON.parse(itemSelected.consumer).lastname">
                      <td class="crud-orders-title-table-custom align-middle">Nombre y Apellido</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{JSON.parse(itemSelected.consumer).name}} {{JSON.parse(itemSelected.consumer).lastname}}
                      </td>
                    </tr> 
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).phone">
                      <td class="crud-orders-title-table-custom align-middle">Teléfono</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b-icon icon="telephone-fill"></b-icon>
                        {{JSON.parse(itemSelected.consumer).phone}}
                      </td>
                    </tr>             
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).email">
                      <td class="crud-orders-title-table-custom align-middle">Email</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).email}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).address">
                      <td class="crud-orders-title-table-custom align-middle">Dirección</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).address}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).city">
                      <td class="crud-orders-title-table-custom align-middle">Ciudad</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).city}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).province">
                      <td class="crud-orders-title-table-custom align-middle">Provincia</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).province}}
                      </td>
                    </tr>             
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).observations">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).observations}}
                      </td>
                    </tr>                                                                    
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-orders"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length"
                        :key="itemDetailForceUpdate">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getProductCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                              
                    <div  v-html="getProductName(data.item)"></div>
                  </template>

                  <template v-slot:cell(list)="data">                                                                   
                    <span v-if="data.item.price_list">
                      {{data.item.price_list.reference}}
                    </span>
                    <span v-else>
                      <span v-if="itemSelected.price_list">
                        {{itemSelected.price_list.reference}}
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(quantity)="data">                              
                    <div  v-if="data.item.observations">

                        <div  :id="'tooltip-button-show-event-' + data.item.id"                              
                              class="text-info">  
                          <b>{{data.item.quantity}}</b>
                          <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                        </div>

                        <b-tooltip  ref="tooltip" 
                                    placement="left"
                                    :target="'tooltip-button-show-event-' + data.item.id">
                          <div v-html="getQuantityVariant(data.item)"></div>
                        </b-tooltip>                                                                           

                    </div>
                    <div v-else>
                      {{data.item.quantity}}
                      <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                    </div>
                  </template>                  

                  <template v-slot:cell(unit_price)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.unit_price)}}
                    </div>
                  </template>

                  <template v-slot:cell(total)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.total)}}
                    </div>
                  </template>

                  <template v-slot:cell(iva)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.amount_iva)}}
                    </div>
                  </template>

                  <template v-slot:cell(total_iva)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.total_iva)}}
                    </div>
                  </template>

                   <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" >
                        
                        <b-dropdown-item @click="openChangeNameProduct(data.item)">
                          <b-icon icon="arrow-clockwise"></b-icon> Cambiar Nombre
                        </b-dropdown-item>                   
                      </b-dropdown>
                   </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
              <b-tab title="Adjuntos" v-if="itemSelected && itemSelected.attachments.length>0">
                <b-list-group>                  
                  <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="item in itemSelected.attachments" :key="item.id">                    
                    <b-link :href="item.file" target="_blank"> 
                      {{item.name}}
                    </b-link>
                    <a href="javascript:void(0);" @click="deleteAttach(item)">
                      <b-badge variant="danger" pill title="Eliminar">                      
                        <b-icon icon="trash-fill"></b-icon>
                      </b-badge>
                    </a>
                  </b-list-group-item>                  
                </b-list-group>                
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

      <!-- CRUD TRACKING -->
      <b-modal v-model="modal.tracking.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.tracking.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Enviar email de aviso al cliente">                
              <b-form-checkbox v-model="crud.tracking.send_email" size="sm" switch></b-form-checkbox>                 
            </b-form-group>            
          </b-col>
          <b-col lg="12" v-if="crud.tracking.send_email">
            <b-form-group label="Información Adicional" description="Puede agregar un mensaje personalizado (ej: link de seguimiento de transporte, horario de retiro en el local, etc). Este mensaje se incluirá en el email de aviso al cliente">
              <b-form-textarea                
                v-model="crud.tracking.delivery_code_tracking"
                placeholder="Agregar información adicional en el aviso por email al cliente..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.tracking.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTracking()">Aceptar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD NRO ORDER INTERNAL -->
      <b-modal v-model="modal.nroInternal.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.nroInternal.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Número Interno" description="Se utiliza para vincular un pedido con un sistema externo">
              <b-form-input type="text"
                            v-model="crud.nroInternal.nro_order_internal"                            
                            placeholder="Ingresar número interno">            
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.nroInternal.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveNroOrderInternal()">Aceptar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD NAME PRODUCT -->
      <b-modal v-model="modal.product.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.product.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre Producto">
              <b-form-input type="text"
                            v-model="crud.product.name"                            
                            placeholder="Ingresar nombre">            
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.product.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveChangeNameProduct()">Cambiar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetail/detailProducts'  
  import Param from '@/config/parameters'
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Storage from '@/handler/storageSession'
  import Metrics from '@/components/inc/metrics/metrics'  

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,
      Detail,
      Printer,   
      Metrics,         
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudOrders',
          elements: {
            deleteOrders: true,
          }
        },  
        parameters: {
          haveNroInteral: Helper.hasParametersAccess(Param.P21),
          remitFromOrder: Helper.hasParametersAccess(Param.P26),
          remitFromSale: Helper.hasParametersAccess(Param.P27),
          multipleItems: Helper.hasParametersAccess(Param.P52),
          haveReason: Helper.hasParametersAccess(Param.P54),
        },               
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },
        tableSub : {
          items: [],
          fields: []
        },  
        tableDetail : {
          items: [],
          fields: []
        },           
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,
            observations_internal: '', 
            erp_points_sales_id: 0,
            erp_points_sales: null,
            price_list_id: 0,
            price_list: null,             
            methods_payment_id: 0,
            methods_payment: null,             
            detail: [],
            email_alternative: '',
            shipping_id: 0,
            shipping: null,
            discounts_price: 0,
            percentage_discount: 0,
            is_retail: false, 
            send_notification_email_customer: true,
            reason: null,
            reason_id : 0,
          },
          status: {
            id: 0,
            status: '',
            old_status: '',
          },
          paid: {
            id: 0,
            paid: false,
            paid_info: '',            
          },
          tracking: {
            order_id: 0,
            delivery_code_tracking: '',
            status:'in_transport',
            send_email: true
          },
          nroInternal: {
            id: 0,
            nro_order_internal: '',            
          },
          product: {
            id: 0,
            name: '',
          },
          print: {
            reference: 'order',
            id: 0,
            key: 0,
          }                                         
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          },
          paid: {
            active: false,
            title: '',
          },
          tracking: {
            active: false,
            title: '',            
          },
          nroInternal: {
            active: false,
            title: '',            
          },
          product: {
            active: false,
            title: '',
          }                                    
        },
        arr: {
          orders: [],   
          export: [],
          filters : {
            number: 0,
            customers: [],
            sellers: [],
            status: [],
            points_sales: [],
            remitted: [
              {code: true, label: 'Remitido'},
              {code: false, label: 'No Remitido'}
            ],
            reasons: [],
          },                       
        },   
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          status: null,
          source: 'product',
          points_sales: null,
          remitted: null,
          typeOperations: 'wholesaler',
          number: '',
          reason: null,
          show_total_iva: false,
        },
        showView: {
          wholesaler: false,
          retailer: false,
          viewActive: ''
        },
        itemSelected: null,   
        configurations: null, 
        itemDetailForceUpdate: 0, 
        itemFilterForceUpdate: 0, 
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.deleteOrders = Helper.hasAccessElement(this.access, 'delete_orders') 
      /* Fin configuracion */
    },  
    mounted() {   
      this.loadConfigurations()            
      this.filterLoad()    
      this.filterOrders()       
      this.loadStyleConfig()      
    },      
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
      moduleReceiptsActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.RECIBOS_ERP) {
            status = true
          }
        })
        return status
      },
      moduleRemitsActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.REMITOS) {
            status = true
          }
        })
        return status
      },   
      moduleSalesActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENTAS) {
            status = true
          }
        })
        return status
      },            

      // OTHERS
      subtotalItemSelected() {
        if(this.getAplicationDiscount) {
          if(this.itemSelected) {
            return parseFloat(this.itemSelected.total) - parseFloat(this.itemSelected.delivery_price) 
          } else {
            return 0
          }
        } else {
          if(this.itemSelected) {
            return parseFloat(this.itemSelected.total) - parseFloat(this.itemSelected.delivery_price) + parseFloat(this.itemSelected.discounts_price)
          } else {
            return 0
          }          
        }
      },
      situationCreditMax() {
        if(this.configurations) {
          return this.configurations[1].value_number;
        } else {
          return 0;
        }        
      },

      // INTEGRATION SYSTEM MANUFACTURE
      getIntegrationManufactureSystem() {
        if(this.configurations) {
          return this.configurations[4].value_string;
        } else {
          return '';
        }        
      },      

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },            
      conditionSuppliersShippingActive(){
        var condition = []
        condition.push({field: 'active', condition: true})
        condition.push({field: 'shipping', condition: true})
        return condition;
      },    
      conditionReason() {
        let condition = []
        if(this.crud.form.customers_id) {
          condition.push({field: 'customer', condition: this.crud.form.customers_id});
        } 
        return condition      
      },
      
      // DISCOUNTS
      getTagDiscount() {
        if(this.configurations && this.configurations[19].value_string) {
          return this.configurations[19].value_string
        } else {
          return 'Descuento'
        }        
      },
      getValueDiscount(){
        if(this.configurations && this.configurations[20].value_string) {
          var value = JSON.parse(this.configurations[20].value_string)
          var arr = []
          value.forEach(element => {
            arr.push({
              value:element,
              text: element + ' %'
            })
          });
          return arr
        } else {
          return null
        }        
      },
      getAplicationDiscount() {        
        if(this.configurations) {                    
          if(this.configurations[21].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },

      // CONFIG DETAIL
      validStock() {
        if(this.configurations) {          
          if(this.configurations[2].value_boolean == 1) {
            return false
          } else {
            return true
          }
        } else {
          return true;
        }    
      },
      editPrice() {
        if(this.configurations) {
          if(this.configurations[3].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },    
      allowProductsDuplicates() {
        if(this.configurations) {
          if(this.configurations[16].value_boolean == 1) {
            return true
          } else {
            if(this.parameters.multipleItems) {
              return true
            } else {
              return false
            }
          }
        } else {
          if(this.parameters.multipleItems) {
            return true
          } else {
            return false
          }
        }         
      },  
      showItemOnlyPublished() {
        var status = this.crud.form.reason && this.crud.form.reason.only_published
        return status
      },

      // PRICES / CANT / CURRENCY
      getSubTotalOrder() {
        var price = 0
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {                           
            price = price + parseFloat(element.quantity) * parseFloat(element.amount)
          })
        }        
        return parseFloat(price)
      },
      getTotalOrder() {
        if(this.getAplicationDiscount) {
          return this.getSubTotalOrder
        } else {
          this.validDiscounts()
          var discounts_price = parseFloat(this.crud.form.discounts_price)

          return this.getSubTotalOrder - discounts_price              
        }
      },
      getCantItemOrder() {
        var cant = this.crud.form.detail.length        
        return parseFloat(cant)
      },
      getCurrencyOrder() {        
        var currency = 'ARS'
        if(this.crud.form.price_list) {                    
          if(this.crud.form.price_list.currency) {                   
            currency = this.crud.form.price_list.currency.code            
          }
        }         
        return currency
      },      

      // VALIDA TRANSPORTE       
      showShipping() {
        if(this.configurations) {                    
          if(this.configurations[18].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },            
      validShipping() {
        if(this.configurations) {                    
          if(this.configurations[17].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },      
    },
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_orders = document.getElementById('view_list_orders')
        var view_footer_orders = document.getElementById('view_footer_orders')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_orders.classList.remove('pr-0')
          view_footer_orders.classList.remove('pr-0')
        } else {          
          view_list_orders.classList.add('pr-0')
          view_footer_orders.classList.add('pr-0')
        }        
      },
      loadConfigurations() {
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data                  
          this.configurations = data    
          
          this.setStatusAltFilter()
          this.loadRenderView()
          this.loadFieldTable()      
        })      
      },      
      loadFieldTable () {
        // TABLE ORDERS        
        this.table.fields = []        
        this.table.fields.push({key: 'business', label: 'Empresa', class:"align-middle text-left", width:"50px"})
        this.table.fields.push({key: 'id', label: 'Nº', class:"align-middle text-center", width:"70px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"70px"})                      
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"140px"})                      
        if(this.showView.viewActive == 'wholesaler') {
          if(this.moduleSellersActive){
            this.table.fields.push({key: 'sellers_id', label: 'Vend', class:"align-middle text-center", width:"30px"})
          }

          this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-center", width:"30px"})
        }
        if(this.filters.show_total_iva) {
          this.table.fields.push({key: 'total', label: 'Total C/IVA', class:"align-middle text-right", width:"80px"})                      
        } else {
          this.table.fields.push({key: 'total', label: 'Total S/IVA', class:"align-middle text-right", width:"80px"})                      
        }
        this.table.fields.push({key: 'status', label: 'Estado', class:"align-middle text-center", width:"100px"})          

        if(this.parameters.haveReason) {
          this.table.fields.push({key: 'reason', label: 'Motivo', class:"align-middle text-center", width:"40px"})         
        }   

        if(this.showView.viewActive == 'wholesaler') {
          this.table.fields.push({key: 'status_system_manufacture', label: '', class:"align-middle text-center", width:"10px"})
        }
        
        if(this.showView.viewActive == 'retailer') {
          this.table.fields.push({key: 'paid_info', label: '', class:"align-middle text-center", width:"20px"})                        
        }

        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"20px"})                        

        if(this.showView.viewActive == 'retailer') {
          this.table.fields.push({key: 'delivery_code_tracking', label: '', class:"align-middle text-center", width:"20px"})                        
        }

        this.table.fields.push({key: 'invoice', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"15px"})

        // TABLE DETALLE
        this.tableSub.fields = []
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Producto', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO    
        this.tableDetail.fields = []    
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"70px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Producto', class:"align-middle text-truncate", width:"250px"})                
        this.tableDetail.fields.push({key: 'list', label: 'Lista', class:"align-middle text-truncate", width:"50px"})                
        this.tableDetail.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-right", width:"100px"})                
        this.tableDetail.fields.push({key: 'unit_price', label: 'P.Unit', class:"align-middle text-right", width:"100px"})                
        this.tableDetail.fields.push({key: 'total', label: 'Total S/IVA', class:"align-middle text-right", width:"100px"}) 
        this.tableDetail.fields.push({key: 'iva', label: 'IVA', class:"align-middle text-right", width:"100px"}) 
        this.tableDetail.fields.push({key: 'total_iva', label: 'Total C/IVA', class:"align-middle text-right", width:"100px"}) 
        this.tableDetail.fields.push({key: 'f_action', label: '', class:"align-middle text-right", width:"50px"})         
      },
      loadRenderView() {        
        this.showView.wholesaler = (this.configurations[14].value_string == 'wholesaler' || this.configurations[14].value_string == 'both')
        this.showView.retailer = (this.configurations[14].value_string == 'retailer' || this.configurations[14].value_string == 'both')

        if(this.showView.wholesaler && this.showView.retailer) {
          this.showView.viewActive = 'wholesaler'
          this.filters.typeOperations = 'wholesaler'
        } else {
          if(this.showView.wholesaler) {
            this.showView.viewActive = 'wholesaler'
            this.filters.typeOperations = 'wholesaler'
          } 
          if(this.showView.retailer) {
            this.showView.viewActive = 'retailer'
            this.filters.typeOperations = 'retailer'
          }
        }
        
      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.orders.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableOrdersShow() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          if(this.parameters.haveNroInteral) {
            arrTable[i].style.minHeight = '490px'
          } else {
            arrTable[i].style.minHeight = '420px'
          }          
        }        
      },
      setConfigTableOrdersHide() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },      
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },     
      
      // RENDER
      selectRenderView(ref) {
        this.showView.viewActive = ref
        this.filters.typeOperations = ref
        this.loadFieldTable()
        this.filterOrders()
      },
      showGenerateRemits(item) {
        var status = false
        if(this.parameters.remitFromOrder) {
          if(!item.sale_id && !item.remitted && item.status=='pending_shipping' && this.moduleRemitsActive) {
            status = true
          }          
        }
        return status
      },
      showGenerateInvoice(item) {
        var status = false   
        if(!this.moduleRemitsActive || !this.parameters.remitFromOrder) {
          if(!item.sale_id && !item.remitted && item.status!='in_process' && this.moduleSalesActive) {
            status = true
          }
        }             
        return status
      },

      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(!this.crud.form.observations && !this.crud.form.id) {
            if(object.observations_delivery_address) {
              this.crud.form.observations = 'ENTREGA: ' + object.observations_delivery_address
            }            
          }            

          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.erp_points_sales_id) {
                this.crud.form.erp_points_sales = object.data_fiscal.points_sales
                this.crud.form.erp_points_sales_id = object.data_fiscal.points_sales_id
              }
            }

            if(object.data_fiscal.methods_payment_id) {
              if(!this.crud.form.methods_payment_id) {
                this.crud.form.methods_payment = object.data_fiscal.methods_payment
                this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id                
              }
            } 

            if(object.data_fiscal.shipping_id) {
              if(!this.crud.form.shipping_id) {
                this.crud.form.shipping = object.data_fiscal.shipping
                this.crud.form.shipping_id = object.data_fiscal.shipping_id
              }
            } 
          }

          if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            } 
          }         

          if(this.parameters.haveReason) {            
            this.crud.form.reason = null
            this.crud.form.reason_id = 0
          }
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.erp_points_sales = object
          this.crud.form.erp_points_sales_id = object.id             
        } else {
          this.crud.form.erp_points_sales = null
          this.crud.form.erp_points_sales_id = 0
        }
      },  
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id             
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },         
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },         
      loadShipping (object) {
        if(object){
          this.crud.form.shipping = object
          this.crud.form.shipping_id = object.id             
        } else {
          this.crud.form.shipping = null
          this.crud.form.shipping_id = 0
        }
      },                
      loadReason (object) {
        if(object){
          this.crud.form.reason = object
          this.crud.form.reason_id = object.id 
          
          if(object && object.price_list_id && object.list) {
            this.crud.form.price_list = object.list
            this.crud.form.price_list_id = object.list.id
          } else {
            if(this.crud.form.customers && this.crud.form.customers.price_list && this.crud.form.customers.price_list.list) {              
              this.crud.form.price_list = this.crud.form.customers.price_list.list
              this.crud.form.price_list_id = this.crud.form.customers.price_list.list.id
            }             
          }
        } else {
          this.crud.form.reason = null
          this.crud.form.reason_id = 0
        }

        this.detailForceUpdate()
      },                      
      prepareExport(data) {      
        this.arr.export = []
        data.forEach(element => {
          var businessID = 0
          var businessName = ""          
          var customersID = 0
          var customersCode = ""
          var customersName = ""
          var pointSalesCode = ""
          var pointSalesName = ""          
          var methodsPaymentID = 0
          var methodsPaymentName = ""      
          var shippingID = 0
          var shippingName = ""
          var reasonID = 0
          var reasonName = ""

          if(element.points_sales && element.points_sales.business) {
            businessID = element.points_sales.business.id            
            businessName = element.points_sales.business.reference
          }

          if(element.customer) {
            customersID = element.customer.id
            customersCode = element.customer.point_sale
            customersName = element.customer.name            
          }
                  
          if(element.points_sales) {            
            pointSalesCode = element.points_sales.point_sale
            pointSalesName = element.points_sales.name            
          }

          if(element.method_payment) {            
            methodsPaymentID = element.method_payment.id
            methodsPaymentName = element.method_payment.name            
          }          

          if(element.shipping) {            
            shippingID = element.shipping.id
            shippingName = element.shipping.name            
          } 
          
          if(element.reason) {            
            reasonID = element.reason.id
            reasonName = element.reason.name
          } 

          this.arr.export.push({
            id: element.id,
            businessID: businessID,
            businessName: businessName,            
            pointSalesCode: pointSalesCode,
            pointSalesName: pointSalesName,
            date: element.date,            
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            methodsPaymentID: methodsPaymentID,
            methodsPaymentName: methodsPaymentName,      
            shippingID: shippingID,
            shippingName: shippingName,
            reasonID: reasonID,
            reasonName: reasonName,
            discount: element.discounts_price.replace('.',','),
            percentage_discount: element.percentage_discount.replace('.',','),
            total: element.total.replace('.',','),
            status: this.getStatusAltByName(element),
            observations: element.observation,
            observations_external: element.observations_external,
            observations_internal: element.observations_internal,
            productID: 0,
            productCode: '',
            productName: '',
            priceListID: 0,
            priceListName: "",              
            quantity: 0,
            unitMeasurementID: 0,
            unitMeasurementName: '',              
            unit_price: 0,
            amount_total: 0,
          })

          element.details.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var unitMeasurementID = 0
            var unitMeasurementName = ""                
            var priceListID = 0
            var priceListName = ""   

            if(element1.products_id && element1.product) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id && element1.products_color) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id && element1.products_waist) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id && element1.products_color_waist) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       

            if(element1.unit_measurement_id && element1.unit_measurement) {
              unitMeasurementID = element1.unit_measurement.id
              unitMeasurementName = element1.unit_measurement.name            
            }      

            if(element1.price_list_id && element1.price_list) {              
              priceListID = element1.price_list.id
              priceListName = element1.price_list.name            
            } else {
              if(element.price_list_id && element.price_list) {
                priceListID = element.price_list.id
                priceListName = element.price_list.name                          
              }
            }

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              priceListID: priceListID,
              priceListName: priceListName,              
              quantity: element1.quantity.replace('.',','),
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,                
              unit_price: element1.unit_price.replace('.',','),
              amount_total: element1.total.replace('.',','),
            })
          })
        });
      },

      // ABM MAIN
      add() {
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null
        this.crud.form.observations_internal = ''
        this.crud.form.erp_points_sales_id = 0
        this.crud.form.erp_points_sales = null
        this.crud.form.methods_payment_id = 0
        this.crud.form.methods_payment = null              
        this.crud.form.price_list_id = 0
        this.crud.form.price_list = null             
        this.crud.form.detail = []
        this.crud.form.email_alternative = ''
        this.crud.form.shipping_id = 0
        this.crud.form.shipping = null        
        this.crud.form.discounts_price = 0
        this.crud.form.percentage_discount = 0
        this.crud.form.is_retail = false
        this.crud.form.send_notification_email_customer = true
        this.crud.form.reason_id = 0
        this.crud.form.reason = null

        this.detailForceUpdate()

        this.modal.form.title = "Nuevo Pedido"
        this.modal.form.active = true
      },
      edit(item) {   
        let loader = this.$loading.show();

        this.crud.form.id = item.id
        this.crud.form.date = moment(item.date).format('YYYY-MM-DD')
        this.crud.form.observations = item.observation
        this.crud.form.staff_id = (item.staff_id) ? item.staff_id : Helper.getStaffId()
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.observations_internal = item.observations_internal
        this.crud.form.erp_points_sales_id = item.erp_points_sales_id
        this.crud.form.erp_points_sales = item.points_sales
        this.crud.form.methods_payment_id = item.methods_payment_id
        this.crud.form.methods_payment = item.method_payment
        this.crud.form.price_list_id = item.price_list_id
        this.crud.form.price_list = item.price_list
        this.crud.form.email_alternative = item.email_alternative
        this.crud.form.shipping_id = item.shipping_id
        this.crud.form.shipping = item.shipping
        this.crud.form.discounts_price = parseFloat(item.discounts_price)
        this.crud.form.percentage_discount = parseFloat(item.percentage_discount)
        this.crud.form.is_retail = item.is_retail
        this.crud.form.send_notification_email_customer = true
        this.crud.form.reason_id = item.reason_id
        this.crud.form.reason = item.reason

        this.detailForceUpdate()

        var result = serviceAPI.mostrarPedido(item.id);

        result.then((response) => {  
          var data = response.data
          this.crud.form.detail = data.detail

          // recalculo el % si no se guardo          
          if(parseFloat(item.discounts_price) && !parseFloat(item.percentage_discount)) {
            this.crud.form.percentage_discount = this.getSubTotalOrder / item.discounts_price
          }
                    
          this.modal.form.title = 'PEDIDO ' + this.crud.form.erp_points_sales.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.id.toString().padStart(8,'0')
          this.modal.form.active = true          
          loader.hide()          
        })
      },
      remove(item) {
        this.crud.form.id = item.id

        this.$bvModal.msgBoxConfirm('¿Desea borrar el pedido #' + this.crud.form.id + '?', {
          title: 'Borrar Pedido',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPedido(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        if(!this.crud.form.erp_points_sales_id) {
          this.$awn.alert('Falta cargar el punto de venta')
          return false
        }
        
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del pedido?', {
          title: 'Generar Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            if (this.crud.form.id) {
              var result = serviceAPI.editarPedido(this.crud.form);
            } else {
              var result = serviceAPI.agregarPedido(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pedido generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },    
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el pedido?', {
          title: 'Cancelar el Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      orderValidDetailGeneral() {        
        var valid = this.crud.form.customers_id && this.crud.form.erp_points_sales_id && this.crud.form.methods_payment_id
        
        if(this.validShipping) {
          valid = valid && this.crud.form.shipping_id
        }

        if(this.parameters.haveReason) {
          valid = valid && this.crud.form.reason_id
        }

        if(valid) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }        
          if(!this.crud.form.erp_points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }
          if(this.validShipping) {
            if(!this.crud.form.shipping_id) {
              this.$awn.alert("No se cargó el transporte");
              return false;
            }            
          }
          if(this.parameters.haveReason) {
            if(!this.crud.form.reason_id) {
              this.$awn.alert("No se cargó el motivo");
              return false;
            }            
          }          
        }        
      },
      orderValidDetailItems() {
        if(this.crud.form.detail.length) {
          return true;
        } else {
          this.$awn.alert("No se cargó ningún item");
          return false;
        }
      },
      detailForceUpdate() {
        this.itemDetailForceUpdate = this.itemDetailForceUpdate + 1
      },
      filterForceUpdate() {
        this.itemFilterForceUpdate = this.itemFilterForceUpdate + 1
      },

      // DISCOUNTS
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }         
        
        var percentage = parseFloat(this.crud.form.percentage_discount)
        this.crud.form.discounts_price = (this.getSubTotalOrder * percentage) / 100        
      },         

      // FILTER
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()  
        this.filterLoadPointSale() 
        this.filterLoadReasons()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage()
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.full_identification })            
          });          
        })   
      }, 
      filterLoadReasons() {
        var result = serviceAPI.obtenerMotivos()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.reasons = []
          data.forEach(element => {                            
            this.arr.filters.reasons.push({ code: element.id, label: element.name })            
          });          
        }) 
      },
      filterOrders(forceOpenSub=false, forceOpenDetail=false) {
        this.saveFilterStorage()
        this.table.isBusy = true
        var result = serviceAPI.filtrarPedido(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data
          this.arr.orders = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {
            if(element.status == 'cancel') {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)

                if(forceOpenDetail) {
                  this.openDetail()
                  this.detailForceUpdate()
                }                
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.loadFieldTable()

          this.table.isBusy = false          
        })           
        .catch(error => {                  
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },
      filterOrdersMetrics() {
        this.filterForceUpdate()
      },
      
      // ORDER DETAIL
      openSub(item) {      
        this.itemSelected = item        
        
        this.tableSub.items = item.details           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },          
      openDetail() {              
        this.tableDetail.items = this.itemSelected.details

        this.modal.detail.title = "Detalle del Pedido"
        this.modal.detail.active = true
      },      
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.code) {
          code = item.code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {        
        var name = ''
        var prod = null

        if(item.description) {
          name = item.description
        }  
                
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      }, 
      getQuantityVariant(item) {        
        var html = ''
        if(item.observations) {          
          var variants = JSON.parse(item.observations)
          variants.forEach((element, index) => {
            var name = element[0]
            var cant = element[1]            
            if(parseFloat(cant)>0) {
              html = html + '<i class="fa fa-circle" style="font-size:8px;"></i> ' + name + '- (<b>' + cant + '</b>)<br>'             
            }            
          });          
        }
        return html
      },

      // STATUS ALTERNTIVOS
      getStatusName(item) {
        var name = ''
        var background = 'black'
        var arrStatus = item.status_alt
        
        switch (item.status) {
          case 'in_process':
            name = arrStatus[0]
            background = '#c1bb00'
            break;
        
          case 'closed':
            name = arrStatus[1]
            background = '#1a85e0'
            break;

          case 'in_preparation':
            name = arrStatus[2]
            background = '#582eda'
            break;

          case 'pending_shipping':
            name = arrStatus[3]
            background = '#ce7910'
            break;

          case 'in_transport':
            name = arrStatus[4]
            background = '#33bf8c'
            break;

          case 'delivered':
            name = arrStatus[5]
            background = '#3e9619'
            break;

          case 'cancel':
            name = arrStatus[6]
            background = '#d23829'
            break;
        }

        // system manufacture status
        if(item.status_system_manufacture && this.configurations) {
          if(JSON.parse(item.status_system_manufacture).status == 'ok') {
            name = this.configurations[11].value_string
            background = '#c72eda'
          }
          if(JSON.parse(item.status_system_manufacture).status == 'error') {
            name = this.configurations[12].value_string
            background = '#ff1f1f'
          } 
        }   
        // fin    

        return '<span style="background-color:' + background + '; color: #fff; font-size:12px; font-weight:700; padding: 2px;">'+ name +"</span>"
      },
      setStatusAltFilter() {
        var arrStatus = this.configurations[9].value_string.split(',')
        var haveSystemManufacture = this.configurations[4].value_string
        var showStatusInProcess = this.configurations[13].value_boolean

        this.arr.filters.status = []

        if(showStatusInProcess) {
          if(arrStatus[0])
            this.arr.filters.status.push({code:'in_process', label: arrStatus[0]})
        }
        
        if(arrStatus[1])
          this.arr.filters.status.push({code:'closed', label: arrStatus[1]})
        
        if(haveSystemManufacture) {        
          this.arr.filters.status.push({code:'pending_manufacture', label: this.configurations[11].value_string})          
          this.arr.filters.status.push({code:'error_manufacture', label: this.configurations[12].value_string})          
        }

        if(arrStatus[2])
          this.arr.filters.status.push({code:'in_preparation', label: arrStatus[2]})
        
        if(arrStatus[3])
          this.arr.filters.status.push({code:'pending_shipping', label: arrStatus[3]})
        
        if(arrStatus[4])
          this.arr.filters.status.push({code:'in_transport', label: arrStatus[4]})
        
        if(arrStatus[5])
          this.arr.filters.status.push({code:'delivered', label: arrStatus[5]})

        if(arrStatus[6])
          this.arr.filters.status.push({code:'cancel', label: arrStatus[6]})              
      },    
      getStatusAltByName(item) {
        var name = ''        
        var arrStatus = item.status_alt
        
        switch (item.status) {
          case 'in_process':
            name = arrStatus[0]            
            break;
        
          case 'closed':
            name = arrStatus[1]            
            break;

          case 'in_preparation':
            name = arrStatus[2]            
            break;

          case 'pending_shipping':
            name = arrStatus[3]            
            break;

          case 'in_transport':
            name = arrStatus[4]            
            break;

          case 'delivered':
            name = arrStatus[5]            
            break;

          case 'cancel':
            name = arrStatus[6]            
            break;
        }

        // system manufacture status
        if(item.status_system_manufacture && this.configurations) {
          if(JSON.parse(item.status_system_manufacture).status == 'ok') {
            name = this.configurations[11].value_string      
          }
          if(JSON.parse(item.status_system_manufacture).status == 'error') {
            name = this.configurations[12].value_string
          } 
        }   
        // fin   

        return name
      },      
      
      // STATUS
      nextStatus(item){     
        var nextStatus = 'closed'   
        switch (item.status) {
          case 'closed':
            nextStatus = 'in_preparation' 
            break;
        
          case 'in_preparation':
            nextStatus = 'pending_shipping'
            break;
            
          case 'pending_shipping':            
            nextStatus = 'in_transport'
            break;          
          
          case 'in_transport':
            nextStatus = 'delivered'
            break;    
        }

        // si el proximo estado es "en transporte"
        // se abre un proceso propio para trabajar este paso de estado
        if(nextStatus=='in_transport') {
          this.openTracking(item)
          return false
        }         
        
        // si tiene una url para integracion, desde el estado 'closed' al 'in_preparation' 
        // se vincula con el sistema de fabricacion
        if(this.getIntegrationManufactureSystem) {
          if(nextStatus=='in_preparation') {
            this.integratedManufactureSystem(item)
            return false
          }                   
        }

        let loader = this.$loading.show();
       
        this.crud.status.id = item.id
        this.crud.status.status = nextStatus        
        this.crud.status.old_status = item.status
        var result = serviceAPI.cambiarEstadoPedido(this.crud.status);
        
        result.then((response) => {        
          loader.hide()
          this.filterOrders()
          this.$awn.success("Estado cambiado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },
      showNextStatus(item){     
        if(this.getIntegrationManufactureSystem) { 
          if(item.status == 'in_preparation') {
            return false
          }          
        } 

        if(item.status == 'in_process' || item.status == 'delivered' || item.status == 'cancel') {
          return false
        } else {
          return true
        }
      },      
      previousStatus(item){
        var nextStatus = 'delivered'   
        switch (item.status) {
          case 'delivered':
            nextStatus = 'in_transport' 
            break;
        
          case 'in_transport':
            nextStatus = 'pending_shipping'
            break;
            
          case 'pending_shipping':
            nextStatus = 'in_preparation'
            break;          
          
          case 'in_preparation':
            nextStatus = 'closed'
            break;             
        }

        let loader = this.$loading.show();
       
        this.crud.status.id = item.id
        this.crud.status.status = nextStatus    
        this.crud.status.old_status = item.status    
        var result = serviceAPI.cambiarEstadoPedido(this.crud.status);
        
        result.then((response) => {        
          loader.hide()
          this.filterOrders()
          this.$awn.success("Estado cambiado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },      
      showPreviousStatus(item){
        if(this.getIntegrationManufactureSystem) { 
          if(item.status == 'in_preparation' || item.status == 'pending_shipping') {
            return false
          }          
        } 

        if(item.status == 'in_process' || item.status == 'closed' || item.status == 'cancel') {
          return false
        } else {
          return true
        }
      },      
      cancelStatus(item){            
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el pedido #' + item.id + '?', {
          title: 'Cancelar el Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
          
            this.crud.status.id = item.id
            this.crud.status.status = 'cancel'        
            var result = serviceAPI.cambiarEstadoPedido(this.crud.status);
            
            result.then((response) => {        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pedido cancelado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },
      showCancelStatus(item){
        if(this.getIntegrationManufactureSystem) { 
          return false          
        }

        if(item.status!='cancel') {
          return true
        } else {
          return false
        }      
      },
      closedStatus(item){   
        this.$bvModal.msgBoxConfirm('¿Desea restaurar el pedido #' + item.id + '?', {
          title: 'Restaurar el Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                  
            let loader = this.$loading.show();
          
            this.crud.status.id = item.id
            this.crud.status.status = 'closed'        
            var result = serviceAPI.cambiarEstadoPedido(this.crud.status);
            
            result.then((response) => {        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pedido restaurado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      showRestoreStatus(item){
        if(this.getIntegrationManufactureSystem) { 
          return false          
        }

        if(item.status=='cancel') {
          return true
        } else {
          return false
        }
      },

      // PAY
      showPaid(item) {                        
        /*
        if(this.moduleReceiptsActive)
         return false 
        */

        if(item.status=='in_process') {
          return false
        } else {
          if(item.paid) {
            return false
          } else {
            return true
          }
        }         
      },
      showCancelPaid(item) {  
        /*
        if(this.moduleReceiptsActive)
         return false 
        */        
        
        if(item.status=='in_process') {
          return false
        } else {
          if(item.paid) {
            return true
          } else {
            return false
          }
        }        
      },      
      changePaid(item) {
        var msj = ''
        if(item.paid) {
          msj = '¿Continuar con la cancelación del pago del pedido #' + item.id + '?'
        } else {
          msj = '¿Desea forzar el pago del pedido #' + item.id + '?'
        }

        this.$bvModal.msgBoxConfirm(msj, {
          title: 'Estado Pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            this.crud.paid.id = item.id
            
            if(item.paid) {
              this.crud.paid.paid = false
              this.crud.paid.paid_info = 'Se forzó la cancelación del pago desde el sistema'          
            } else {
              this.crud.paid.paid = true
              this.crud.paid.paid_info = 'Se forzó el pago desde el sistema'                        
            }
            
            var result = serviceAPI.cambiarPago(this.crud.paid);
            
            result.then((response) => {        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pago forzado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                
      },
      getLinkPay(item) {
        var msj = '¿Desea obtener el link de pago?'
        
        this.$bvModal.msgBoxConfirm(msj, {
          title: 'Link de Pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
                        
            var result = serviceAPI.getLinkPago({
              id: item.id,
            });
            
            result.then((response) => {        
              loader.hide()   
              
              var link = response.data

              this.copyLinkPay(link)
              window.open(link,
                "Pagar",
                "toolbar=no," +
                "scrollbars=no," +
                "location=no," +
                "statusbar=no," +
                "menubar=no," +
                "resizable=0," +
                "width=1000," +
                "height=800," +
                "left=300," +
                "top=100"
              );                  
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      copyLinkPay(text) {
        var divContainer = document.getElementById("link_container");
        var divLink = document.createElement("div");
        divLink.setAttribute("id", 'link_suscription');
        divLink.textContent = text;      
        divContainer.appendChild(divLink);

        var codigoACopiar = document.getElementById('link_suscription')        
        var seleccion = document.createRange(); 
        seleccion.selectNodeContents(codigoACopiar);

        window.getSelection().removeAllRanges();
        window.getSelection().addRange(seleccion); 

        
        var res = document.execCommand('copy');
        if (res){
          this.$awn.success("Link de Pago copiado en portapapeles");
        } else {
          this.$awn.alert("No se pudo copiar el Link de Pago");
        }    
                
        divLink.remove()        
      },

      // DELIVERY
      openTracking(item) {
        this.crud.tracking.order_id = item.id
        this.crud.tracking.delivery_code_tracking = ''
        this.crud.tracking.status = 'in_transport'
        this.crud.tracking.send_email = true

        this.modal.tracking.title = "Transporte / Retiro"        
        this.modal.tracking.active = true
      },
      saveTracking() {
        this.$bvModal.msgBoxConfirm('¿Confirmar transporte del pedido #' + this.crud.tracking.order_id + '?', {
          title: 'Transporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
                      
            var result = serviceAPI.trackingPedido(this.crud.tracking);
            
            result.then((response) => {        
              this.modal.tracking.active = false
              loader.hide()              
              this.filterOrders()
              this.$awn.success("Pedido enviado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },

      // INTEGRATION MANUFACTURE SYSTEM
      integratedManufactureSystem(item) {        
        this.$bvModal.msgBoxConfirm('¿Confirmar sincronización del pedido #' + item.id + '?', {
          title: 'Sincronización',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                    
            let loader = this.$loading.show();
                      
            var result = serviceAPI.integratedManufactureSystemPedido({
              url: this.configurations[4].value_string,
              data: item
            });
            
            result.then((response) => {      
              var data = response.data                    
              serviceAPI.cambiarEstadoSistemaProduccion({
                id: item.id,
                status: 'ok',
                status_system_manufacture: data,
              });
              
              loader.hide()              
              this.filterOrders()
              this.$awn.success("Pedido sincronizado");
            })
            .catch(error => {                   
              serviceAPI.cambiarEstadoSistemaProduccion({
                id: item.id,
                status: 'error',
                status_system_manufacture: error.response.data,
              });
              loader.hide()    
              this.filterOrders()              
              this.$awn.alert(Error.showError(error))
            })            
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        }) 
      },
            
      // CREDIT SITUATION 
      situationCreditMessage(item) {
        if(item.customer) {
          var message = "<b>Fecha:</b> "+ moment(item.customer.bcra_status.fecha).format('DD/MM/YYYY') +"<br><b>Situación:</b> "+ item.customer.bcra_status.situacion +"<br><b>Cheques Rechazados:</b> " + item.customer.bcra_status.rechazos
          return message;
        } else {
          return '';
        }        
      },

      // NRO INTERNAL
      openNroOrderInternal(item) {
        this.crud.nroInternal.id = item.id
        this.crud.nroInternal.nro_order_internal = item.nro_order_internal

        this.modal.nroInternal.title = "Número Interno"        
        this.modal.nroInternal.active = true        
      },
      saveNroOrderInternal() {
        this.$bvModal.msgBoxConfirm('¿Confirma el cambio?', {
          title: 'Agregar Número Interno',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
                      
            var result = serviceAPI.changeNroOrderInternal(this.crud.nroInternal);
            
            result.then((response) => {        
              this.modal.nroInternal.active = false
              loader.hide()              
              this.filterOrders()
              this.$awn.success("Se cambio el número interno con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },      

      // ADJUNTOS
      deleteAttach(item) {        
        this.$bvModal.msgBoxConfirm('¿Desea borrar el archivo adjunto [' + item.name + '] del pedido Nº: ' + item.order_id + '?', {
          title: 'Borrar Adjunto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarAdjunto(item.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterOrders(true, true)
              this.$awn.success("Adjunto eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      

      // PROCESS ADMINISTRATIVE
      generateRemits(item) {
        this.$router.push({ name: 'RemitsStaffRemitsGenerateByOrders', params: {ordersID: item.id} })
      },      
      generateInvoice(item) {
        this.$router.push({ name: 'SalesStaffSalesGenerateByOrders', params: {ordersID: item.id} })
      },

      // CHANGE PRODUCT NAME
      openChangeNameProduct(item) {
        this.crud.product.id = item.id
        this.crud.product.name = item.description

        this.modal.product.title = "Edición Nombre Producto"        
        this.modal.product.active = true    
      },
      saveChangeNameProduct() {
        this.$bvModal.msgBoxConfirm('¿Cambiar nombre del producto?', {
          title: 'Nombre de Producto',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
                      
            var result = serviceAPI.changeNameProduct(this.crud.product);
            
            result.then((response) => {        
              this.modal.product.active = false
              loader.hide()              
              this.filterOrders(true, true)
              this.$awn.success("Se cambio el nombre del producto con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        }) 
      },

      // SEND and PRINT
      sendEmailOrder(item) {        
        let msj = 'Está a punto de reenviar el email con el detalle del pedido Nº #' + item.id + '. ¿Desea continuar?'
        
        this.$bvModal.msgBoxConfirm(msj, {
          title: 'Reenviar Email',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            var result = serviceAPI.sendEmailOrder({id: item.id});
            
            result.then((response) => {        
              loader.hide()              
              this.$awn.success("Email Enviado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      sendPrinterOrder(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_orders', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_orders')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_orders'))
        }         
      },      
    }
  }
</script>
<style>
  .crud-orders-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-orders-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-orders-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-orders-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-orders-value-table-custom {
    padding-left: 5px;
  }  
  .table-full-detail-orders {
    overflow: auto;
    max-height: 350px;    
  }
  .orders-alert-retailer {
    font-weight: bold;
    color: gray;
    font-size: 12px;
  }
  .orders-alert-noretailer {
    font-weight: bold;
    color: darkblue;
    font-size: 12px;
  } 
  .order-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .order-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }
  .crud-orders-nro-internal {
    color: var(--mdc-theme-primary);
    font-size: 12px;
  }
  .crud-new-orders-button-add {
    margin-top: 29px;
  }
  .crud-orders-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-orders-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }    
  .crud-orders-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }
  .crud-orders-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }      
  .crud-orders-totales-discounts-items {
    padding-top: 5px;
  }    
  .crud-orders-wizard .wizard-header {
    padding: 0px;
  }  
  #view_list_orders .card-body {
    padding: 5px;
  }
  #tab-orders .tab-content .tab-pane {
    padding: 2px;
  }
  #view_list_orders .card-header {
    padding: 5px;
  }  
</style>
<style scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;    
  }
  .crud-orders-observations {
    overflow: auto;
    max-height: 70px; 
    font-size: 12px;   
  }
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>